import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2009 Mensajes" />
      <h1>2009 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2009, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.
      </p>
      <h2>2009 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>13/09/09</td>
            <td>Jason Henderson</td>
            <td>Volviéndonos Al Propósito de Dios &nbsp;&nbsp;</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a
                  href={asset('mensajes/09/pdf/090913VolviendonosAlPropositoDeDios.pdf')}
                >
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/09/09</td>
            <td>Jason Henderson</td>
            <td>La Gran División&nbsp;&nbsp;&nbsp;</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/090920LaGranDivision.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>04/10/09</td>
            <td>Jason Henderson</td>
            <td>Salvación 01 &nbsp;- Salvación Es Cristo, y Es Ahora</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091004Salvacion01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>11/10/09</td>
            <td>Jason Henderson</td>
            <td>Salvación 02&nbsp; - La Puerta Con Sangre En Ella</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091011Salvacion02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>18/10/09</td>
            <td>Jason Henderson</td>
            <td>Salvación 03&nbsp; - El Final de Una Creación</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091018Salvacion03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/10/09</td>
            <td>Jason Henderson</td>
            <td>La Palabra 01</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091025Palabra01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>01/11/09</td>
            <td>Jason Henderson</td>
            <td>La Palabra 02</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091101Palabra02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>08/11/09</td>
            <td>Jason Henderson</td>
            <td>La Palabra 03</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091108Palabra03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>15/11/09</td>
            <td>Jason Henderson</td>
            <td>Pacto 01</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091115Pacto01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/11/09</td>
            <td>Jason Henderson</td>
            <td>Pacto 02</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091122Pacto02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>06/12/09</td>
            <td>Jason Henderson</td>
            <td>Pacto 03</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091206Pacto03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/12/09</td>
            <td>Jason Henderson</td>
            <td>Pacto 04</td>
            <td>
              <div></div>
            </td>
            <td>
              <div>
                <a href={asset('mensajes/09/pdf/091220Pacto04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="70">27/12/09</td>
            <td width="130">Jason Henderson</td>
            <td>El Nuevo Pacto</td>
            <td width="50">
              <div></div>
            </td>
            <td width="40">
              <div>
                <a href={asset('mensajes/09/pdf/091227NuevoPacto.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
